import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import InnerHero from "../components/InnerHero"
import Contact2 from "../sections/contacts/Contact2"
import Faq2 from "../sections/faqs/Faq2"

const ContactPage2 = ({ data }) => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasMiddleSocial: true,
          bottomWrapperClassName: "!justify-center",
          bottomClassName: "bg-primary-25",
        }}
      >
        <GatsbySeo
          title={`Kloft - Contact 02`}
          description={`We serve globally to modernise static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <InnerHero
          title="Contact 02"
          text="Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day."
        />
        <Contact2 />
        <Faq2 />
      </PageWrapper>
    </>
  )
}

export default ContactPage2
