import React from "react"
import { Link } from "gatsby"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import Button from "../../components/Button"
import Input from "../../components/forms/Input"
import TextArea from "../../components/forms/TextArea"

const Contact2 = ({ className }) => {
  return (
    <>
      <div>
        <Section className={`bg-neutral-50 ${className}`}>
          <Container>
            {/* section-wrap */}
            <div className="grid items-center justify-center grid-cols-1 ">
              {/* contact */}
              <div
                data-aos="zoom-in"
                className="flex flex-col bg-white gap-8 tablet:gap-14 laptop:gap-[82px] tablet:px-[140px] px-5 py-5 tablet:py-16 w-full max-w-[772px] mx-auto rounded-3xl"
              >
                {/* texts */}
                <div className="flex flex-col gap-6">
                  <SectionTitle className="!pb-0 text-center">
                    Get in touch
                  </SectionTitle>
                  <SectionText className="!pb-0 text-center">
                    We’d love to hear from you. Contact us.
                  </SectionText>
                </div>
                {/* from-and-button */}
                <form className="flex flex-col gap-6 tablet:gap-8">
                  {/* from */}
                  <div className="flex flex-col gap-5 tablet:gap-6 ">
                    <div className="flex flex-col gap-6 tablet:flex-row">
                      <Input
                        label="First name"
                        placeholder="John"
                        name="first-name"
                      />
                      <Input
                        label="Last name"
                        placeholder="Doe"
                        name="last-name"
                      />
                    </div>
                    <Input
                      type="email"
                      label="Email"
                      placeholder="johndoe@example.com"
                      name="email"
                    />
                    <TextArea label="Additional details" name="textarea" />
                    <Input
                      type="checkbox"
                      id="Checkbox2"
                      name="checkbox"
                      label={
                        <>
                          I agree to the{" "}
                          <Link to="#">
                            <span className="font-medium text-body-md text-primary-600">
                              privacy policy.
                            </span>{" "}
                          </Link>
                        </>
                      }
                    />
                  </div>
                  <Button type="submit" size={"xl"}>
                    Send message
                  </Button>
                </form>
              </div>
            </div>
          </Container>
        </Section>
      </div>
    </>
  )
}

export default Contact2
