import React, { useState } from "react"
import { ChevronDown } from "react-bootstrap-icons"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"

const AccordionItem = ({
  title = "Accordion title?",
  text = "Bring to the table win-win survival",
  className = "",
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const toggleAcccordion = () => {
    setIsOpened(!isOpened)
  }
  return (
    <div>
      <div
        data-aos="fade-up"
        className={`px-5 py-4 border border-neutral-200 rounded-2xl ${className}`}
      >
        {/* icon & text */}
        <button
          className="flex items-center justify-between w-full p-3"
          onClick={toggleAcccordion}
        >
          <p className="font-medium text-left text-body-lg text-neutral-700">
            {title}
          </p>
          <ChevronDown
            className={`text-neutral-500 transition-all duration-300 ${
              isOpened ? "rotate-180" : ""
            }`}
          />
        </button>
        <div
          className={`transition-all duration-500 ${
            isOpened ? "flex" : " hidden"
          }`}
        >
          <p className={`font-normal text-body-md pt-6 text-neutral-500`}>
            {text}
          </p>
        </div>
      </div>
    </div>
  )
}

const Faq2 = ({ className }) => {
  return (
    <>
      <Section className={className}>
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col gap-8 tablet:gap-14 laptop:gap-[82px]">
            {/* top-part*/}
            <div
              data-aos="fade-in"
              className="flex flex-col items-center justify-center mx-auto max-w-[614px]"
            >
              <SectionTitle className="text-center">
                Frequently asked{" "}
                <span className="text text-primary-600">questions</span>
              </SectionTitle>
              <SectionText className="text-center !pb-0 ">
                Rapaciously seize adaptive infomediaries and user-centric
                intellectual capital. Collaboratively unleash market-driven
                "outside the box".
              </SectionText>
            </div>
            {/* bottom-part */}
            <div className="grid gap-4 tablet:gap-6 laptop:grid-cols-2">
              {/* faq-item */}
              <AccordionItem
                title="Does Kloft read my customers'all data?"
                text="Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward"
              />
              <AccordionItem
                title="What's your refund and cancellation policy?"
                text="User generated content in real-time will have multiple touchpoints for offshoring."
              />
              <AccordionItem
                title="What makes Bizzy different from other analytics tools?"
                text="User generated content in real-time will have multiple touchpoints for offshoring."
              />
              <AccordionItem
                title="What makes Kloft different from other analytics tools?"
                text="Along the information highway will close the loop on focusing solely on the bottom line."
              />
              <AccordionItem
                title="How do you can some take payments?"
                text="Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive convergence on cross-platform integration."
              />
              <AccordionItem
                title="Can I also track website analytics Kloft"
                text="Quickly maximize timely deliverables for real-time schemas. Dramatically maintain clicks-and-mortar solutions without functional solutions."
              />
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}
export default Faq2
